<script lang="ts" setup>
import { useRegisterInvitation } from '~/composables/useRegisterInvitation'

withDefaults(
  defineProps<{
    color?: string
    invited?: boolean
  }>(),
  {
    color: '#4D4985',
    invited: false
  }
)

const route = useRoute()
const slug = route.params.register as string

const { isInvited, invitationUuid } = useRegisterInvitation(slug)

const isOpen: Ref<boolean> = ref(false)

const drawer = (): void => {
  isOpen.value = !isOpen.value
}

watch(isOpen, (value: boolean) => {
  if (value) document.body.style.setProperty('overflow', 'hidden')
  else document.body.style.removeProperty('overflow')
})

const keydownHandler = (e: KeyboardEvent): void => {
  if (e.key === 'Escape' && isOpen.value) isOpen.value = false
}

onMounted(() => {
  document.addEventListener('keydown', keydownHandler)
})

onBeforeUnmount(() => {
  document.removeEventListener('keydown', keydownHandler)
})
</script>

<template>
  <nav class="fixed bottom-5 z-50 w-full overflow-hidden">
    <div
      :style="{ backgroundColor: color }"
      class="fixed bottom-6 right-6 flex h-16 w-16 cursor-pointer items-center justify-center rounded-full shadow-md lg:right-6 lg:top-6"
      @click="drawer"
    >
      <Icon class="h-7 w-7 text-white" name="heroicons:bars-3-16-solid" />
    </div>

    <transition
      enter-active-class="ease-out transition-medium"
      enter-class="opacity-0"
      enter-to-class="opacity-100"
      leave-active-class="ease-out transition-medium"
      leave-class="opacity-100"
      leave-to-class="opacity-0"
    >
      <div
        v-show="isOpen"
        class="fixed inset-0 z-10 transition-opacity"
        @keydown.esc="isOpen = false"
      >
        <div
          class="absolute inset-0 bg-black opacity-50"
          tabindex="0"
          @click="isOpen = false"
        ></div>
      </div>
    </transition>
    <aside
      :class="isOpen ? 'translate-x-0' : 'translate-x-full'"
      :style="{ 'background-color': color }"
      class="fixed right-0 top-0 z-30 h-full w-full transform overflow-auto bg-white transition-all duration-300 ease-in-out"
    >
      <div class="wrap flex-wrap items-center justify-between">
        <div class="mt-4 flex w-full cursor-pointer items-center justify-end pr-4" @click="drawer">
          <p class="mr-2 items-center text-white">Sluiten</p>
          <Icon class="h-8 w-8 text-white" name="heroicons:x-circle" />
        </div>

        <div class="flex h-screen">
          <ul class="m-auto w-full space-y-9 text-center text-xl font-bold text-white" tabindex="0">
            <li>
              <NuxtLink
                :to="`/${slug}/rouwbericht`"
                class="rounded-lg px-8 py-3 font-bold text-white"
                exactActiveClass="font-bold underline"
                @click="drawer"
              >
                Rouwbericht
              </NuxtLink>
            </li>
            <li v-if="isInvited">
              <NuxtLink
                :to="`/${slug}/uitvaartinformatie/${invitationUuid}`"
                class="rounded-lg px-8 py-3 font-bold text-white"
                exactActiveClass="font-bold underline"
                @click="drawer"
              >
                Uitvaartinformatie
              </NuxtLink>
            </li>
            <li>
              <NuxtLink
                :to="`/${slug}/familiepagina`"
                class="rounded-lg px-8 py-3 font-bold text-white"
                exactActiveClass="font-bold underline"
                @click="drawer"
              >
                Familiepagina
              </NuxtLink>
            </li>
            <li>
              <NuxtLink
                :to="`/${slug}/wensen`"
                class="rounded-lg px-8 py-3 font-bold text-white"
                exactActiveClass="font-bold underline"
                @click="drawer"
              >
                Uitvaartwensen
              </NuxtLink>
            </li>
            <li>
              <NuxtLink
                class="rounded-lg px-8 py-3 font-bold text-white"
                exactActiveClass="font-bold underline"
                to="/steun-bieden"
                @click="drawer"
              >
                Steun bieden
              </NuxtLink>
            </li>
            <li>
              <NuxtLink
                :to="`/${slug}/contact`"
                class="rounded-lg px-8 py-3 font-bold text-white"
                exactActiveClass="font-bold underline"
                @click="drawer"
              >
                Contact
              </NuxtLink>
            </li>
          </ul>
        </div>
      </div>
    </aside>
  </nav>
</template>
